import {getStudent, getUser, isStudent, getTeacher} from './UserService';
import _ from 'lodash';
import bridge from 'common/bridge';

export function isCoppa() {
    const user = getUser();
    const teacher = getTeacher();
    let isCoppa = _.result(user, 'isCoppa', _.result(getStudent(), 'isInBelowThirteenClass', false))
    if (_.isEmpty(teacher)) {
      const classes = _.result(bridge, 'page_data.classes', []);
      if (classes.length > 0) {
		const coppaClass = classes.find(classObj => classObj.belowThirteen);
		isCoppa = !!coppaClass;
	  }
    }
    return isCoppa;
}

export function isCoppaStudent() {
  return isStudent() && isCoppa();
}
