import _ from 'lodash';
import abtest from 'common/abtest';
import bridge from 'common/bridge';
import log from 'common/Logger';
import {toEpoch, getCreatedDateEpoch, getClasses} from 'common/UserService';
import {isCoppa} from 'common/coppa';
import {default_dfp_config} from './dfp_config_default';
import Utils from '../../common/Utils';
import {getUserId} from "../../common/UserService";

const config = _.result(abtest, 'adConfig.config', default_dfp_config);
config.ad_config_id = _.result(abtest, 'adConfig.id', 0);
config.ad_config_name = _.result(abtest, 'adConfig.name', 0);
config.is_coppa = isCoppa();

const targetingMap = {
  'ad_config': '' + config.ad_config_id,
  'ab_test': '' + abtest.abTestId,
  'ab_test_var': '' + abtest.uid,
  'coppa': '' + config.is_coppa,
};

_.defaults(targetingMap, _.result(config, 'options.dfp_targeting_map', {}))

const testVal = Utils.getUrlParams()['rt-test']
if (testVal) {
  targetingMap['test']=''+testVal
}


//////////////////////////// quiz key values
if (window.currentQuizId) {
  targetingMap['ArticleID'] = ''+window.currentQuizId
}
let passage_level = _.result(bridge, 'page_data.command.level', null)
if (!_.isEmpty(passage_level)) {
  targetingMap['passage_level'] = _.toLower(passage_level);
  localStorage.setItem('passage_level', targetingMap['passage_level']);
} else {
  passage_level = localStorage.getItem('passage_level');
  if (!_.isEmpty(passage_level)) {
    targetingMap['passage_level'] = passage_level;
  }
}

const classes = getClasses();
if (!_.isEmpty(classes)) {
  targetingMap.in_class = ''+true;
  targetingMap.classes = _.join(_.sortBy(_.keys(classes), () => {
  }), '_');
} else {
  targetingMap.in_class = ''+false;
}

const createdDateEpoch = getCreatedDateEpoch();
if (createdDateEpoch) {
  const minimumTime = toEpoch('2020-09-11');
  if (createdDateEpoch > minimumTime) {
    targetingMap.new_user = '1';
  } else {
    targetingMap.new_user = '0';
  }
}

const userId = getUserId();
if (userId) {
  targetingMap.uuid = userId;
}


log.debug(`AdConfig - ${config.ad_config_name} {id: ${config.ad_config_id}}`);
log.debug(`Coppa Compliance - ${config.is_coppa}`);
config.targetingMap = targetingMap;

//externalize for 3rd party scripts
window.rt = window.rt || {};
window.rt.ads = window.rt.ads || {};
window.rt.ads.dfp_targeting_map = targetingMap;

export default config;
