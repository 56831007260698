//production overrides
// export const BASE_PATH='';
export const BASE_PATH = $$APP_BASE_PATH$$;
export const FEATURE_FLAG_COPPA = 'updateCoppa';
export const FEATURE_FLAG_COPPA_FORCED_ENABLED = 'update_classes_enabled';
export const RT_GA_ID = $$GA_ID$$;
export const RT_GA4_TAG_ID = $$GA_4_TAG_ID$$;
export const RT_ANALYTICS_API = $$ANALYTICS_API_URI$$




/**
 * Default
 *********************************************************/
export default {
	logLevel: $$LOG_LEVEL$$, // affects console only, values are listed in constants.logger.levels
	logTables: null, // tables display state
	abTestId: 1,
};

/**
 * Constants
 *********************************************************/
export const constants = {
	windowKey: 'RTClient',
	version: $$VERSION$$, // Version
	appBasePath: $$APP_BASE_PATH$$, //the base path for the server routes e.g. /
	apiPath: $$API_PATH$$, // Data API path (server)
	adsApiPath: $$ADS_API_PATH$$, // Ads API path
	loggerPath: `${$$LOGGING_PATH$$}/Log`, // Logging server path
	stripePublicKey: $$STRIPE_PUBLIC_KEY$$,
	classApiPath: $$REST_API_PATH$$,
	schoolApiPath: $$SCHOOL_REST_API_PATH$$,
	activityApiPath: $$ACTIVITY_REST_API_PATH$$,
	subscriptionApiPath: $$SUBSCRIPTION_API_PATH$$,
	newSubscriptionApiPath: $$NEW_SUBSCRIPTION_API_PATH$$,
	mapApiPath: $$MAP_API_PATH$$,
	studentAppPath: $$STUDENT_APP_PATH$$,
	settingsApiPath: $$SETTINGS_API_PATH$$,

	coppaDomain: $$COPPA_DOMAIN$$,
	nonCoppaDomain: $$NON_COPPA_DOMAIN$$,
	/**
	 * Logger
	 */
	logger: {
		prefix: 'RT::',
		levels: {
			alwaysSend: -999,
			none: 0,
			alert: 1,
			critical: 2,
			error: 3,
			warn: 4,
			notify: 5,
			info: 6,
			debug: 7,
			trace: 8,
		},
		colors: {
			yellow: { isLoggerStyle: true, style: 'color: #f5d206;' },
			red: { isLoggerStyle: true, style: 'color: #8c0000;' },
			blue: { isLoggerStyle: true, style: 'color: #2196f3;' },
			orange: { isLoggerStyle: true, style: 'color: #ff8c00;' },
			paleRed: { isLoggerStyle: true, style: 'background: #fe4444; color: #000000;' },
			paleYellow: { isLoggerStyle: true, style: 'background: #faffca; color: #000000;' },
			paleGreen: { isLoggerStyle: true, style: 'background: #e4ffd6; color: #000000;' },
			paleBlue: { isLoggerStyle: true, style: 'background: #98bdf1; color: #000000;' },
			palePurple: { isLoggerStyle: true, style: 'background: #ce93d8; color: #ffffff;' },
		},
	},
	user: {
		DEFAULT_LANGUAGE: 'en-US',
	},
	localStorageKeys: {
		test: 'rt_test',
		logLevel: 'rt_logLevel',
		logTables: 'rt_logTables',
		userId: 'rt_userId',
		timeline: 'rt_timeline',
	},
	DEFAULT_ALERT_DURATION: 2000,
	studentLevels: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve'],
	ORDER_ASCENDING: 'order-asc',
	ORDER_DESCENDING: 'order-desc',
	REFRESH_TIMEOUT_ERROR_MSG: 'Eeek. It appears your session has timed out. Please refresh your browser and try again',
	NOT_FOR_NEXT_ANSWER_ERROR_MSG: 'Selected answer is not for the next unanswered question; answer is for question',
	ACTIVITY_TAB_CURRENT: 1,
	ACTIVITY_TAB_PAST: 2,
	GLOBAL_COMPETITION_START_DATE: new Date(2022, 0, 8),
	GLOBAL_COMPETITION_END_DATE: new Date(2022, 0, 15),
	DEBOUNCE_TIMEOUT: 500,
	dateRangeType: {
		sevenDays: 0,
		nextWeek: 1,
		oneMonth: 2,
		nextMonth: 3,
		customRange: 4
	},
	ACTIVITY_CURRENT_TAB_WEEK_DAYS: 2,
	ORDER_DIRECTION_ASCENDING: 'asc',
	ORDER_DIRECTION_DESCENDING: 'desc',
	PERFORMANCE_MAP: {
		Vocabulary: 'Craft and Structure',
		Reasoning: 'Integration of Knowledge',
		Research: 'Key Ideas and Details',
	},
	PRETEST_STATUS_MAP: {
		PRETEST_COMPLETE: "pretest_complete",
		PRETEST_IN_PROGRESS: "pretest_in_progress",
		PRETEST_NOT_STARTED: "pretest_not_started"
	},
	SIGNUP_STATUS_TO_STEP: {
		'2': {
			step: 2,
			name: 'Personal Details'
		},
		'3': {
		  step: 3,
		  name: 'Find a school'
		},
		'4': {
		  step: 4,
		  name: 'Create a calssroom'
		},
		'5': {
		  step: 5,
		  name: 'Choose the plan'
		}
	},
	ASSETS_BASE_DIR: '/assets/app',
	INVITE_STATUS_TO_INFO: {
		'0': {
			icon: '/assets/app/class/icon-completed.svg',
			name: 'JOINED'
		},
		'1': {
			icon: '/assets/app/quiz/icon-pending.svg',
			name: 'PENDING'
		},
		'2': {
			icon: '/assets/app/class/icon-closed.svg',
			name: 'REJECTED'
		},
		'3': {
			icon: '/assets/app/class/icon-closed.svg',
			name: 'DELETED'
		}
	},
	REPORT_TASK_FINISHED: 'Report generation finished',
	INITIAL_MAP_LOCATION_LATITUDE: 41.8336314,
	INITIAL_MAP_LOCATION_LONGITUDE: -88.0190399,
	MINIMUM_MAP_ZOOM_LEVEL: 3,
	MAXIMUM_MAP_ZOOM_LEVEL: 18,
	MAP_TILE_LAYER_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	TEACHER_SCHOOL_SEARCH_VISIT: 'teacher_school_search_visit',
	AB_TEST_NAME: 'rt-ab',
	PASSAGE_ACTIVITY_STEP_COUNT: 5,
	INCOMPLETED_ACTIVITY_DATA: 'incompleted_activity_data',
	NOTIFICATION_TYPE_INFO: {
		info: {
		  text: "Attention!",
		  icon: "/assets/app/class/icon-notification-info.svg",
		},
		warning: {
		  text: "",
		  icon: "/assets/app/class/icon-warning-info.svg",
		},
		error: {
		  text: "",
		  icon: "/assets/app/class/icon-closed-red.svg",
		},
	},
	DEFAULT_SCHOOL_COUNTRY_CODE: 'USA'
};

/**
 * Enums
 *********************************************************/
export const enums = {
	abTests: {
		NO_ADS: 9998,
	},
	analytics: {
		gaId: $$GA_ID$$
	},
	platforms: {
		DESKTOP: 1,
		MOBILE: 2,
		TABLET: 3,
	},
	settings: {},
	quizPopoverTypes: {
		ANSWERED: 1,
		CHALLENGE_ANSWERED: 2,
		SCROLLED: 3,
		FAST_ANSWERED: 4,
		RESPONSE_SENT: 5,
		RESPONSE_SKIPPED: 6
	},
	signUpStatus: {
		SIGN: 1,
		UPDATE_INFO: 2,
		SCHOOL_INFO: 3,
		CREATE_CLASS: 4,
		PRICING: 5,
		COMMON: 6,
	},
	signUpStepsCount: {
		TEACHER_WITH_INVITE_AND_PRICING: 5,
		TEACHER_WITH_PRICING: 4,
		TEACHER_WITHOUT_PRICING: 3,
		COMMON_STUDENT: 2
	},
	invitationStatus: {
		APPROVED: 0,
		PENDING: 1,
		REJECTED: 2,
		DELETED: 3
	}
};

export const GDPR_COUNTRIES = [
	{ name: 'Austria', code: 'AT' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Republic of Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Greece', code: 'EL' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'United Kingdom', code: 'UK' }
];

export const DEFAULT_ACTIVITY_TYPE = 'FIXED_WEEKLY';
export const ACTIVITY_SPECIFIC_PASSAGE = 'PASSAGE';
export const ACTIVITY_GLOBAL_COMPETITION = 'GLOBAL_COMPETITION';
export const ACTIVITY_LOCAL_COMPETITION = 'LOCAL_COMPETITION';


export const ACTIVITY_TYPE_TITLE = {
	RECURRING: DEFAULT_ACTIVITY_TYPE,
	SPECIFIC_PASSAGE: ACTIVITY_SPECIFIC_PASSAGE,
	SPECIFIC_PASSAGE_FREE: ACTIVITY_SPECIFIC_PASSAGE,
	GLOBAL_COMPETITION: ACTIVITY_GLOBAL_COMPETITION,
	LOCAL_COMPETITION: ACTIVITY_LOCAL_COMPETITION
};

export const ACTIVITY_TYPES = [
	{
		title: 'Recurring Weekly Activity',
		description: 'Set a goal for your students each week. Passages will be automatically selected by  ReadTheory and tailored to the individual student.',
		icon: '/assets/app/class/icon-weekly-assignment.svg',
		type: DEFAULT_ACTIVITY_TYPE
	},
	{
		title: 'Assign a Specific Passage',
		subTitle: 'One-time quiz',
		description: 'Choose from our library of passages to assign a specific quiz for your students to work on.',
		icon: '/assets/app/quiz/icon-head.svg',
		type: ACTIVITY_SPECIFIC_PASSAGE
	},
	// {
	//     title: '2022 Knowledge week competition',
	//     description: `Have your classes compete globally to see who can collect more points. <br/>Starts at <b>START_DATE</b> at <b>12:00AM EDT.</b>`,
	//     background: '/assets/app/class/icon-competition-bg.svg',
	//     icon: '/assets/app/class/icon-global-competition.svg',
	//     type: ACTIVITY_GLOBAL_COMPETITION
	// },
	{
		title: 'Start a Competition',
		description: 'Students will compete to see who can earn the most points',
		icon: '/assets/app/class/icon-global-competition.svg',
		type: ACTIVITY_LOCAL_COMPETITION
	}
];

export const FAQ_LIST = [{
	question: 'How does ReadTheory pricing work?',
	answer: 'We’ve designed our pricing to offer the highest value to our users as possible. With an average cost per student of under $3.50/year, ReadTheory premium is priced to allow everyone the option of accessing our amazing features. If you don’t fit neatly into one of our pricing categories, contact <span style="text-decoration: underline">support@readtheory.org</span> and we’ll help find the right plan for your and your students.'
}, {
	question: 'Do you offer a free trial?',
	answer: 'We offer a no-questions-asked 30 day money back guarantee. If you want to get a refund, simply contact our support team at <span style="text-decoration: underline">support@readtheory.org</span>.'
}, {
	question: 'Why should I get the annual plan?',
	answer: 'The annual plan offers a 20% discount on the quoted monthly prices and offers you the best value.'
}, {
	question: 'Are you working on other features?',
	answer: 'Absolutely! Our team is constantly working on improving ReadTheory’s offering and creating the best experience for our teachers and students.'
}, {
	question: 'I have more questions!',
	answer: 'No problem, use the chat bubble in the bottom right side of your browser or <span style="text-decoration: underline">support@readtheory.org</span> to contact us, and a member of our team will help you with your questions.'
}];


export const SCHOOLS_FAQ_LIST = [{
	question: 'Do you accept purchase orders?',
	answer: 'Absolutely! Use the Get a Quote button at the top of the page to produce a quote, issue the purchase order, and send it over to us to ReadTheory Education Services, inc., 4023 Kennett Pike #50018, Wilmington, DE 19807, USA'
}, {
	question: 'Can I also get an invoice?',
	answer: 'Sure! Reach out to our support team via the chat bubble or by emailing <span style="text-decoration: underline">support@readtheory.org</span>, send over the name of your school and billing address, and we will issue an invoice to your email address.'
}, {
	question: 'Is there an option for small schools?',
	answer: 'Yes, reach out to our support team for a special offer for buildings with 200 or fewer students.'
}, {
	question: 'Do you sell district subscriptions as well?',
	answer: 'We sure do! Message us at <span style="text-decoration: underline">ron@readtheory.org</span> or by using our chat bubble, and we will send out an offer right away. Be sure to include the name of your district, the number of schools interested, and the number of students.'
}, {
	question: 'I have another question!',
	answer: 'No problem, use the chat bubble in the bottom right side of your browser or <span style="text-decoration: underline">support@readtheory.org</span> to contact us, and a member of our team will help you with your questions.'
}];



export const SUBSCRIPTION_REVIEWERS = [{
	icon: '/assets/app/class/icon-reviewer1.png',
	name: 'Mary K',
	role: '11-grade teacher',
	feedback: 'ReadTheory motivates the kids to do more. They are hooked.',
}, {
	icon: '/assets/app/class/icon-reviewer2.png',
	name: 'Simon E.A',
	role: 'Middle school English teacher',
	feedback: "The kids love it. They even ask me to ‘do ReadTheory’ when they wait for others to finish their test.",
}, {
	icon: '/assets/app/class/icon-reviewer3.png',
	name: 'Judy M',
	role: 'Middle school intervention specialist',
	feedback: 'I recommend this program to all my Special Education teachers as a good way to see exactly where their students are functioning and what they can and cannot do well.'
}];

export const AVATAR_BG_COLORS = [
	'#FEEAA5', '#F2C56D', '#FF9874', '#6DF2F2', '#009292', '#CFEBDD', '#92DFB9', '#55DE9A', '#37C780', '#107D47', '#DBE8FE', '#9BC1FF', '#5092FF', '#2162CD', '#1E356A', '#F0DEFC', '#EBD8F8', '#D1BBE0', '#A876C9', '#5C0098'
];
export const CLASS_AVATAR_CONFIG = {
	total: 29,
	colors: ['#1E356A', '#FF9874', '#5092FF', '#F2C56D', '#37C780']
};

export const QUESTION_TYPE = {
	Vocabulary: 'Craft and Structure',
	Research: 'Key Ideas and Details',
	Reasoning: 'Integration of Knowledge'
};

export const STANDARD_REPORT_DATA = {
	student_summary: {},
	class_summary: [{
		type: 'Research',
		totalQuestions: 100,
		correctQuestions: 85,
		coreTypesSummary: []
	}, {
		type: 'Reasoning',
		totalQuestions: 100,
		correctQuestions: 70,
		expanded: true,
		coreTypesSummary: [{
			type: 'Central Idea/Summary',
			totalQuestions: 100,
			correctQuestions: 83
		}, {
			type: 'Author’s Purpose/Effect',
			totalQuestions: 100,
			correctQuestions: 22
		}, {
			type: 'Inference',
			totalQuestions: 100,
			correctQuestions: 72
		}, {
			type: 'Theme',
			totalQuestions: 100,
			correctQuestions: 80
		}, {
			type: 'Compare and Contrast',
			totalQuestions: 0,
			correctQuestions: 0
		}, {
			type: 'Tone',
			totalQuestions: 0,
			correctQuestions: 0
		}]
	}, {
		type: 'Vocabulary',
		totalQuestions: 100,
		correctQuestions: 80,
		coreTypesSummary: []
	}]
};

export const CURRICULUM_REPORT_EMPTY_DATA = [
	{
	  type: 'Research',
	  totalQuestions: 0,
	  correctQuestions: 0,
	  coreTypesSummary: [
		{totalQuestions: 0, type: "Author's Purpose", correctQuestions: 0},
		{totalQuestions: 0, type: "Central Idea", correctQuestions: 0},
		{totalQuestions: 0, type: "Characterization", correctQuestions: 0},
		{totalQuestions: 0, type: "Compare and Contrast", correctQuestions: 0},
		{totalQuestions: 0, type: "Inference", correctQuestions: 0},
		{totalQuestions: 0, type: "Theme Tone", correctQuestions: 0}
	  ]
	}, {
	  type: 'Reasoning',
	  totalQuestions: 0,
	  correctQuestions: 0,
	  coreTypesSummary: [
		{totalQuestions: 0, type: "Exclusion", correctQuestions: 0},
		{totalQuestions: 0, type: "Explicit", correctQuestions: 0},
		{totalQuestions: 0, type: "Making Connections", correctQuestions: 0},
		{totalQuestions: 0, type: "Sequence", correctQuestions: 0},
		{totalQuestions: 0, type: "Supporting Details", correctQuestions: 0},
		{totalQuestions: 0, type: "True or False", correctQuestions: 0}
	  ]
	}, {
	  type: 'Vocabulary',
	  totalQuestions: 0,
	  correctQuestions: 0,
	  coreTypesSummary: [
		{totalQuestions: 0, type: "Application of Literary Elements", correctQuestions: 0},
		{totalQuestions: 0, type: "Text Structure", correctQuestions: 0},
		{totalQuestions: 0, type: "Vocab", correctQuestions: 0}
	  ]
	}
];

export const CURRICULUM_DETAIL_CONTENTS = {
	"Application of Literary Elements": "Students are provided with the definition of a literary element (metaphor, personification, etc.) and are able to successfully identify or interpret that literary element within the text.",
	"Text Structure": "Students are able to analyze how a text is structured and/or describe the effect of the structure on the text.",
	"Vocab":  "Students are able to determine the meaning of unknown words in context.",
	"Exclusion": "Students are able to determine which details are not explicitly mentioned within the text.",
	"Explicit": "Students are able to identify details which are explicitly mentioned within the text.",
	"Making Connections": "Students are able to describe the relationship between elements within the text.",
	"Sequence": "Students are able to describe the sequence of event or describe cause and effect relationships within the text.",
	"Supporting Details": "Students are able to determine which details support a conclusion or the central idea within a text.",
	"Author's Purpose": "Students are able to describe the author’s purpose in crafting the passage. This purpose can be either general (E.g. to inform, entertain, persuade, etc.) or specific (E.g. to inform readers about the dangers of lightning strikes).",
	"Central Idea": "Students are able to identify the central idea of the text.",
	"Characterization": "Students are able to describe aspects of a character and/or to analyze the ways in which the author presented those aspects to the reader.",
	"Compare and Contrast": "Students are able to describe the similarities and differences between aspects of the text.",
	"Inference": "Students are able to interpret the information within the text in order to generate an educated guess or prediction about the text.",
	"Theme Tone": "Students are able to determine the theme or tone within the text.",
};

export const GENERAL_ERROR_MSG_SUFFIX = 'please refresh the page or contact the support team if the issue persists.'

export const CLASS_GRADE_LEVELS = [
 {label: 'Grade 1', value: 1},
 {label: 'Grade 2', value: 2},
 {label: 'Grade 3', value: 3},
 {label: 'Grade 4', value: 4},
 {label: 'Grade 5', value: 5},
 {label: 'Grade 6', value: 6},
 {label: 'Grade 7', value: 7},
 {label: 'Grade 8', value: 8},
 {label: 'Grade 9', value: 9},
 {label: 'Grade 10', value: 10},
 {label: 'Grade 11', value: 11},
 {label: 'Grade 12', value: 12},
 {label: 'ESL', value: 13},
 {label: 'Intervention', value: 14},
 {label: 'Special Ed', value: 15},
 {label: 'Other', value: 16}
];

export const GOOGLE_ANALYTICS_METRIC_MAP = {
  metirc1: 'quiz_count_increment',
  metric2: 'quiz_complete'
};

export const COUNTRY_DATA = [
	{name: 'Afghanistan', alpha2: 'AF', alpha3: 'AFG'},
	{name: 'Åland Islands', alpha2: 'AX', alpha3: 'ALA'},
	{name: 'Albania', alpha2: 'AL', alpha3: 'ALB'},
	{name: 'Algeria', alpha2: 'DZ', alpha3: 'DZA'},
	{name: 'American Samoa', alpha2: 'AS', alpha3: 'ASM'},
	{name: 'Andorra', alpha2: 'AD', alpha3: 'AND'},
	{name: 'Angola', alpha2: 'AO', alpha3: 'AGO'},
	{name: 'Anguilla', alpha2: 'AI', alpha3: 'AIA'},
	{name: 'Antarctica', alpha2: 'AQ', alpha3: 'ATA'},
	{name: 'Antigua and Barbuda', alpha2: 'AG', alpha3: 'ATG'},
	{name: 'Argentina', alpha2: 'AR', alpha3: 'ARG'},
	{name: 'Armenia', alpha2: 'AM', alpha3: 'ARM'},
	{name: 'Aruba', alpha2: 'AW', alpha3: 'ABW'},
	{name: 'Australia', alpha2: 'AU', alpha3: 'AUS'},
	{name: 'Austria', alpha2: 'AT', alpha3: 'AUT'},
	{name: 'Azerbaijan', alpha2: 'AZ', alpha3: 'AZE'},
	{name: 'Bahamas', alpha2: 'BS', alpha3: 'BHS'},
	{name: 'Bahrain', alpha2: 'BH', alpha3: 'BHR'},
	{name: 'Bangladesh', alpha2: 'BD', alpha3: 'BGD'},
	{name: 'Barbados', alpha2: 'BB', alpha3: 'BRB'},
	{name: 'Belarus', alpha2: 'BY', alpha3: 'BLR'},
	{name: 'Belgium', alpha2: 'BE', alpha3: 'BEL'},
	{name: 'Belize', alpha2: 'BZ', alpha3: 'BLZ'},
	{name: 'Benin', alpha2: 'BJ', alpha3: 'BEN'},
	{name: 'Bermuda', alpha2: 'BM', alpha3: 'BMU'},
	{name: 'Bhutan', alpha2: 'BT', alpha3: 'BTN'},
	{name: 'Bolivia (Plurinational State of)', alpha2: 'BO', alpha3: 'BOL'},
	{name: 'Bonaire, Sint Eustatius and Saba', alpha2: 'BQ', alpha3: 'BES'},
	{name: 'Bosnia and Herzegovina', alpha2: 'BA', alpha3: 'BIH'},
	{name: 'Botswana', alpha2: 'BW', alpha3: 'BWA'},
	{name: 'Bouvet Island', alpha2: 'BV', alpha3: 'BVT'},
	{name: 'Brazil', alpha2: 'BR', alpha3: 'BRA'},
	{name: 'British Indian Ocean Territory', alpha2: 'IO', alpha3: 'IOT'},
	{name: 'Brunei Darussalam', alpha2: 'BN', alpha3: 'BRN'},
	{name: 'Bulgaria', alpha2: 'BG', alpha3: 'BGR'},
	{name: 'Burkina Faso', alpha2: 'BF', alpha3: 'BFA'},
	{name: 'Burundi', alpha2: 'BI', alpha3: 'BDI'},
	{name: 'Cabo Verde', alpha2: 'CV', alpha3: 'CPV'},
	{name: 'Cambodia', alpha2: 'KH', alpha3: 'KHM'},
	{name: 'Cameroon', alpha2: 'CM', alpha3: 'CMR'},
	{name: 'Canada', alpha2: 'CA', alpha3: 'CAN'},
	{name: 'Cayman Islands', alpha2: 'KY', alpha3: 'CYM'},
	{name: 'Central African Republic', alpha2: 'CF', alpha3: 'CAF'},
	{name: 'Chad', alpha2: 'TD', alpha3: 'TCD'},
	{name: 'Chile', alpha2: 'CL', alpha3: 'CHL'},
	{name: 'China', alpha2: 'CN', alpha3: 'CHN'},
	{name: 'Christmas Island', alpha2: 'CX', alpha3: 'CXR'},
	{name: 'Cocos (Keeling) Islands', alpha2: 'CC', alpha3: 'CCK'},
	{name: 'Colombia', alpha2: 'CO', alpha3: 'COL'},
	{name: 'Comoros', alpha2: 'KM', alpha3: 'COM'},
	{name: 'Congo', alpha2: 'CG', alpha3: 'COG'},
	{name: 'Congo, Democratic Republic of the', alpha2: 'CD', alpha3: 'COD'},
	{name: 'Cook Islands', alpha2: 'CK', alpha3: 'COK'},
	{name: 'Costa Rica', alpha2: 'CR', alpha3: 'CRI'},
	{name: "Côte d'Ivoire", alpha2: 'CI', alpha3: 'CIV'},
	{name: 'Croatia', alpha2: 'HR', alpha3: 'HRV'},
	{name: 'Cuba', alpha2: 'CU', alpha3: 'CUB'},
	{name: 'Curaçao', alpha2: 'CW', alpha3: 'CUW'},
	{name: 'Cyprus', alpha2: 'CY', alpha3: 'CYP'},
	{name: 'Czechia', alpha2: 'CZ', alpha3: 'CZE'},
	{name: 'Denmark', alpha2: 'DK', alpha3: 'DNK'},
	{name: 'Djibouti', alpha2: 'DJ', alpha3: 'DJI'},
	{name: 'Dominica', alpha2: 'DM', alpha3: 'DMA'},
	{name: 'Dominican Republic', alpha2: 'DO', alpha3: 'DOM'},
	{name: 'Ecuador', alpha2: 'EC', alpha3: 'ECU'},
	{name: 'Egypt', alpha2: 'EG', alpha3: 'EGY'},
	{name: 'El Salvador', alpha2: 'SV', alpha3: 'SLV'},
	{name: 'Equatorial Guinea', alpha2: 'GQ', alpha3: 'GNQ'},
	{name: 'Eritrea', alpha2: 'ER', alpha3: 'ERI'},
	{name: 'Estonia', alpha2: 'EE', alpha3: 'EST'},
	{name: 'Eswatini', alpha2: 'SZ', alpha3: 'SWZ'},
	{name: 'Ethiopia', alpha2: 'ET', alpha3: 'ETH'},
	{name: 'Falkland Islands (Malvinas)', alpha2: 'FK', alpha3: 'FLK'},
	{name: 'Faroe Islands', alpha2: 'FO', alpha3: 'FRO'},
	{name: 'Fiji', alpha2: 'FJ', alpha3: 'FJI'},
	{name: 'Finland', alpha2: 'FI', alpha3: 'FIN'},
	{name: 'France', alpha2: 'FR', alpha3: 'FRA'},
	{name: 'French Guiana', alpha2: 'GF', alpha3: 'GUF'},
	{name: 'French Polynesia', alpha2: 'PF', alpha3: 'PYF'},
	{name: 'French Southern Territories', alpha2: 'TF', alpha3: 'ATF'},
	{name: 'Gabon', alpha2: 'GA', alpha3: 'GAB'},
	{name: 'Gambia', alpha2: 'GM', alpha3: 'GMB'},
	{name: 'Georgia', alpha2: 'GE', alpha3: 'GEO'},
	{name: 'Germany', alpha2: 'DE', alpha3: 'DEU'},
	{name: 'Ghana', alpha2: 'GH', alpha3: 'GHA'},
	{name: 'Gibraltar', alpha2: 'GI', alpha3: 'GIB'},
	{name: 'Greece', alpha2: 'GR', alpha3: 'GRC'},
	{name: 'Greenland', alpha2: 'GL', alpha3: 'GRL'},
	{name: 'Grenada', alpha2: 'GD', alpha3: 'GRD'},
	{name: 'Guadeloupe', alpha2: 'GP', alpha3: 'GLP'},
	{name: 'Guam', alpha2: 'GU', alpha3: 'GUM'},
	{name: 'Guatemala', alpha2: 'GT', alpha3: 'GTM'},
	{name: 'Guernsey', alpha2: 'GG', alpha3: 'GGY'},
	{name: 'Guinea', alpha2: 'GN', alpha3: 'GIN'},
	{name: 'Guinea-Bissau', alpha2: 'GW', alpha3: 'GNB'},
	{name: 'Guyana', alpha2: 'GY', alpha3: 'GUY'},
	{name: 'Haiti', alpha2: 'HT', alpha3: 'HTI'},
	{name: 'Heard Island and McDonald Islands', alpha2: 'HM', alpha3: 'HMD'},
	{name: 'Holy See', alpha2: 'VA', alpha3: 'VAT'},
	{name: 'Honduras', alpha2: 'HN', alpha3: 'HND'},
	{name: 'Hong Kong', alpha2: 'HK', alpha3: 'HKG'},
	{name: 'Hungary', alpha2: 'HU', alpha3: 'HUN'},
	{name: 'Iceland', alpha2: 'IS', alpha3: 'ISL'},
	{name: 'India', alpha2: 'IN', alpha3: 'IND'},
	{name: 'Indonesia', alpha2: 'ID', alpha3: 'IDN'},
	{name: 'Iran (Islamic Republic of)', alpha2: 'IR', alpha3: 'IRN'},
	{name: 'Iraq', alpha2: 'IQ', alpha3: 'IRQ'},
	{name: 'Ireland', alpha2: 'IE', alpha3: 'IRL'},
	{name: 'Isle of Man', alpha2: 'IM', alpha3: 'IMN'},
	{name: 'Israel', alpha2: 'IL', alpha3: 'ISR'},
	{name: 'Italy', alpha2: 'IT', alpha3: 'ITA'},
	{name: 'Jamaica', alpha2: 'JM', alpha3: 'JAM'},
	{name: 'Japan', alpha2: 'JP', alpha3: 'JPN'},
	{name: 'Jersey', alpha2: 'JE', alpha3: 'JEY'},
	{name: 'Jordan', alpha2: 'JO', alpha3: 'JOR'},
	{name: 'Kazakhstan', alpha2: 'KZ', alpha3: 'KAZ'},
	{name: 'Kenya', alpha2: 'KE', alpha3: 'KEN'},
	{name: 'Kiribati', alpha2: 'KI', alpha3: 'KIR'},
	{name: "Korea (Democratic People's Republic of)", alpha2: 'KP', alpha3: 'PRK'},
	{name: 'Korea, Republic of', alpha2: 'KR', alpha3: 'KOR'},
	{name: 'Kuwait', alpha2: 'KW', alpha3: 'KWT'},
	{name: 'Kyrgyzstan', alpha2: 'KG', alpha3: 'KGZ'},
	{name: "Lao People's Democratic Republic", alpha2: 'LA', alpha3: 'LAO'},
	{name: 'Latvia', alpha2: 'LV', alpha3: 'LVA'},
	{name: 'Lebanon', alpha2: 'LB', alpha3: 'LBN'},
	{name: 'Lesotho', alpha2: 'LS', alpha3: 'LSO'},
	{name: 'Liberia', alpha2: 'LR', alpha3: 'LBR'},
	{name: 'Libya', alpha2: 'LY', alpha3: 'LBY'},
	{name: 'Liechtenstein', alpha2: 'LI', alpha3: 'LIE'},
	{name: 'Lithuania', alpha2: 'LT', alpha3: 'LTU'},
	{name: 'Luxembourg', alpha2: 'LU', alpha3: 'LUX'},
	{name: 'Macao', alpha2: 'MO', alpha3: 'MAC'},
	{name: 'Madagascar', alpha2: 'MG', alpha3: 'MDG'},
	{name: 'Malawi', alpha2: 'MW', alpha3: 'MWI'},
	{name: 'Malaysia', alpha2: 'MY', alpha3: 'MYS'},
	{name: 'Maldives', alpha2: 'MV', alpha3: 'MDV'},
	{name: 'Mali', alpha2: 'ML', alpha3: 'MLI'},
	{name: 'Malta', alpha2: 'MT', alpha3: 'MLT'},
	{name: 'Marshall Islands', alpha2: 'MH', alpha3: 'MHL'},
	{name: 'Martinique', alpha2: 'MQ', alpha3: 'MTQ'},
	{name: 'Mauritania', alpha2: 'MR', alpha3: 'MRT'},
	{name: 'Mauritius', alpha2: 'MU', alpha3: 'MUS'},
	{name: 'Mayotte', alpha2: 'YT', alpha3: 'MYT'},
	{name: 'Mexico', alpha2: 'MX', alpha3: 'MEX'},
	{name: 'Micronesia (Federated States of)', alpha2: 'FM', alpha3: 'FSM'},
	{name: 'Moldova, Republic of', alpha2: 'MD', alpha3: 'MDA'},
	{name: 'Monaco', alpha2: 'MC', alpha3: 'MCO'},
	{name: 'Mongolia', alpha2: 'MN', alpha3: 'MNG'},
	{name: 'Montenegro', alpha2: 'ME', alpha3: 'MNE'},
	{name: 'Montserrat', alpha2: 'MS', alpha3: 'MSR'},
	{name: 'Morocco', alpha2: 'MA', alpha3: 'MAR'},
	{name: 'Mozambique', alpha2: 'MZ', alpha3: 'MOZ'},
	{name: 'Myanmar', alpha2: 'MM', alpha3: 'MMR'},
	{name: 'Namibia', alpha2: 'NA', alpha3: 'NAM'},
	{name: 'Nauru', alpha2: 'NR', alpha3: 'NRU'},
	{name: 'Nepal', alpha2: 'NP', alpha3: 'NPL'},
	{name: 'Netherlands', alpha2: 'NL', alpha3: 'NLD'},
	{name: 'New Caledonia', alpha2: 'NC', alpha3: 'NCL'},
	{name: 'New Zealand', alpha2: 'NZ', alpha3: 'NZL'},
	{name: 'Nicaragua', alpha2: 'NI', alpha3: 'NIC'},
	{name: 'Niger', alpha2: 'NE', alpha3: 'NER'},
	{name: 'Nigeria', alpha2: 'NG', alpha3: 'NGA'},
	{name: 'Niue', alpha2: 'NU', alpha3: 'NIU'},
	{name: 'Norfolk Island', alpha2: 'NF', alpha3: 'NFK'},
	{name: 'North Macedonia', alpha2: 'MK', alpha3: 'MKD'},
	{name: 'Northern Mariana Islands', alpha2: 'MP', alpha3: 'MNP'},
	{name: 'Norway', alpha2: 'NO', alpha3: 'NOR'},
	{name: 'Oman', alpha2: 'OM', alpha3: 'OMN'},
	{name: 'Pakistan', alpha2: 'PK', alpha3: 'PAK'},
	{name: 'Palau', alpha2: 'PW', alpha3: 'PLW'},
	{name: 'Palestine, State of', alpha2: 'PS', alpha3: 'PSE'},
	{name: 'Panama', alpha2: 'PA', alpha3: 'PAN'},
	{name: 'Papua New Guinea', alpha2: 'PG', alpha3: 'PNG'},
	{name: 'Paraguay', alpha2: 'PY', alpha3: 'PRY'},
	{name: 'Peru', alpha2: 'PE', alpha3: 'PER'},
	{name: 'Philippines', alpha2: 'PH', alpha3: 'PHL'},
	{name: 'Pitcairn', alpha2: 'PN', alpha3: 'PCN'},
	{name: 'Poland', alpha2: 'PL', alpha3: 'POL'},
	{name: 'Portugal', alpha2: 'PT', alpha3: 'PRT'},
	{name: 'Puerto Rico', alpha2: 'PR', alpha3: 'PRI'},
	{name: 'Qatar', alpha2: 'QA', alpha3: 'QAT'},
	{name: 'Réunion', alpha2: 'RE', alpha3: 'REU'},
	{name: 'Romania', alpha2: 'RO', alpha3: 'ROU'},
	{name: 'Russian Federation', alpha2: 'RU', alpha3: 'RUS'},
	{name: 'Rwanda', alpha2: 'RW', alpha3: 'RWA'},
	{name: 'Saint Barthélemy', alpha2: 'BL', alpha3: 'BLM'},
	{name: 'Saint Helena, Ascension and Tristan da Cunha', alpha2: 'SH', alpha3: 'SHN'},
	{name: 'Saint Kitts and Nevis', alpha2: 'KN', alpha3: 'KNA'},
	{name: 'Saint Lucia', alpha2: 'LC', alpha3: 'LCA'},
	{name: 'Saint Martin (French part)', alpha2: 'MF', alpha3: 'MAF'},
	{name: 'Saint Pierre and Miquelon', alpha2: 'PM', alpha3: 'SPM'},
	{name: 'Saint Vincent and the Grenadines', alpha2: 'VC', alpha3: 'VCT'},
	{name: 'Samoa', alpha2: 'WS', alpha3: 'WSM'},
	{name: 'San Marino', alpha2: 'SM', alpha3: 'SMR'},
	{name: 'Sao Tome and Principe', alpha2: 'ST', alpha3: 'STP'},
	{name: 'Saudi Arabia', alpha2: 'SA', alpha3: 'SAU'},
	{name: 'Senegal', alpha2: 'SN', alpha3: 'SEN'},
	{name: 'Serbia', alpha2: 'RS', alpha3: 'SRB'},
	{name: 'Seychelles', alpha2: 'SC', alpha3: 'SYC'},
	{name: 'Sierra Leone', alpha2: 'SL', alpha3: 'SLE'},
	{name: 'Singapore', alpha2: 'SG', alpha3: 'SGP'},
	{name: 'Sint Maarten (Dutch part)', alpha2: 'SX', alpha3: 'SXM'},
	{name: 'Slovakia', alpha2: 'SK', alpha3: 'SVK'},
	{name: 'Slovenia', alpha2: 'SI', alpha3: 'SVN'},
	{name: 'Solomon Islands', alpha2: 'SB', alpha3: 'SLB'},
	{name: 'Somalia', alpha2: 'SO', alpha3: 'SOM'},
	{name: 'South Africa', alpha2: 'ZA', alpha3: 'ZAF'},
	{name: 'South Georgia and the South Sandwich Islands', alpha2: 'GS', alpha3: 'SGS'},
	{name: 'South Sudan', alpha2: 'SS', alpha3: 'SSD'},
	{name: 'Spain', alpha2: 'ES', alpha3: 'ESP'},
	{name: 'Sri Lanka', alpha2: 'LK', alpha3: 'LKA'},
	{name: 'Sudan', alpha2: 'SD', alpha3: 'SDN'},
	{name: 'Suriname', alpha2: 'SR', alpha3: 'SUR'},
	{name: 'Svalbard and Jan Mayen', alpha2: 'SJ', alpha3: 'SJM'},
	{name: 'Sweden', alpha2: 'SE', alpha3: 'SWE'},
	{name: 'Switzerland', alpha2: 'CH', alpha3: 'CHE'},
	{name: 'Syrian Arab Republic', alpha2: 'SY', alpha3: 'SYR'},
	{name: 'Taiwan, Province of China', alpha2: 'TW', alpha3: 'TWN'},
	{name: 'Tajikistan', alpha2: 'TJ', alpha3: 'TJK'},
	{name: 'Tanzania, United Republic of', alpha2: 'TZ', alpha3: 'TZA'},
	{name: 'Thailand', alpha2: 'TH', alpha3: 'THA'},
	{name: 'Timor-Leste', alpha2: 'TL', alpha3: 'TLS'},
	{name: 'Togo', alpha2: 'TG', alpha3: 'TGO'},
	{name: 'Tokelau', alpha2: 'TK', alpha3: 'TKL'},
	{name: 'Tonga', alpha2: 'TO', alpha3: 'TON'},
	{name: 'Trinidad and Tobago', alpha2: 'TT', alpha3: 'TTO'},
	{name: 'Tunisia', alpha2: 'TN', alpha3: 'TUN'},
	{name: 'Turkey', alpha2: 'TR', alpha3: 'TUR'},
	{name: 'Turkmenistan', alpha2: 'TM', alpha3: 'TKM'},
	{name: 'Turks and Caicos Islands', alpha2: 'TC', alpha3: 'TCA'},
	{name: 'Tuvalu', alpha2: 'TV', alpha3: 'TUV'},
	{name: 'Uganda', alpha2: 'UG', alpha3: 'UGA'},
	{name: 'Ukraine', alpha2: 'UA', alpha3: 'UKR'},
	{name: 'United Arab Emirates', alpha2: 'AE', alpha3: 'ARE'},
	{name: 'United Kingdom of Great Britain and Northern Ireland', alpha2: 'GB', alpha3: 'GBR'},
	{name: 'United States of America', alpha2: 'US', alpha3: 'USA'},
	{name: 'United States Minor Outlying Islands', alpha2: 'UM', alpha3: 'UMI'},
	{name: 'Uruguay', alpha2: 'UY', alpha3: 'URY'},
	{name: 'Uzbekistan', alpha2: 'UZ', alpha3: 'UZB'},
	{name: 'Vanuatu', alpha2: 'VU', alpha3: 'VUT'},
	{name: 'Venezuela (Bolivarian Republic of)', alpha2: 'VE', alpha3: 'VEN'},
	{name: 'Viet Nam', alpha2: 'VN', alpha3: 'VNM'},
	{name: 'Virgin Islands (British)', alpha2: 'VG', alpha3: 'VGB'},
	{name: 'Virgin Islands (U.S.)', alpha2: 'VI', alpha3: 'VIR'},
	{name: 'Wallis and Futuna', alpha2: 'WF', alpha3: 'WLF'},
	{name: 'Western Sahara', alpha2: 'EH', alpha3: 'ESH'},
	{name: 'Yemen', alpha2: 'YE', alpha3: 'YEM'},
	{name: 'Zambia', alpha2: 'ZM', alpha3: 'ZMB'},
	{name: 'Zimbabwe', alpha2: 'ZW', alpha3: 'ZWE'}
];

export const USA_STATES = [
  { name: "Alabama", alpha2: "AL" },
  { name: "Alaska", alpha2: "AK" },
  { name: "Arizona", alpha2: "AZ" },
  { name: "Arkansas", alpha2: "AR" },
  { name: "American Samoa", alpha2: "AS" },
  { name: "California", alpha2: "CA" },
  { name: "Colorado", alpha2: "CO" },
  { name: "Connecticut", alpha2: "CT" },
  { name: "Delaware", alpha2: "DE" },
  { name: "District of Columbia", alpha2: "DC" },
  { name: "Florida", alpha2: "FL" },
  { name: "Georgia", alpha2: "GA" },
  { name: "Guam", alpha2: "GU" },
  { name: "Hawaii", alpha2: "HI" },
  { name: "Idaho", alpha2: "ID" },
  { name: "Illinois", alpha2: "IL" },
  { name: "Indiana", alpha2: "IN" },
  { name: "Iowa", alpha2: "IA" },
  { name: "Kansas", alpha2: "KS" },
  { name: "Kentucky", alpha2: "KY" },
  { name: "Louisiana", alpha2: "LA" },
  { name: "Maine", alpha2: "ME" },
  { name: "Maryland", alpha2: "MD" },
  { name: "Massachusetts", alpha2: "MA" },
  { name: "Michigan", alpha2: "MI" },
  { name: "Minnesota", alpha2: "MN" },
  { name: "Mississippi", alpha2: "MS" },
  { name: "Missouri", alpha2: "MO" },
  { name: "Montana", alpha2: "MT" },
  { name: "Nebraska", alpha2: "NE" },
  { name: "Nevada", alpha2: "NV" },
  { name: "New Hampshire", alpha2: "NH" },
  { name: "New Jersey", alpha2: "NJ" },
  { name: "New Mexico", alpha2: "NM" },
  { name: "New York", alpha2: "NY" },
  { name: "North Carolina", alpha2: "NC" },
  { name: "North Dakota", alpha2: "ND" },
  { name: "Northern Mariana Islands", alpha2: "MP" },
  { name: "Ohio", alpha2: "OH" },
  { name: "Oklahoma", alpha2: "OK" },
  { name: "Oregon", alpha2: "OR" },
  { name: "Pennsylvania", alpha2: "PA" },
  { name: "Puerto Rico", alpha2: "PR" },
  { name: "Rhode Island", alpha2: "RI" },
  { name: "South Carolina", alpha2: "SC" },
  { name: "South Dakota", alpha2: "SD" },
  { name: "Tennessee", alpha2: "TN" },
  { name: "Texas", alpha2: "TX" },
  { name: "Trust Territories", alpha2: "TT" },
  { name: "Utah", alpha2: "UT" },
  { name: "Vermont", alpha2: "VT" },
  { name: "Virginia", alpha2: "VA" },
  { name: "Virgin Islands", alpha2: "VI" },
  { name: "Washington", alpha2: "WA" },
  { name: "West Virginia", alpha2: "WV" },
  { name: "Wisconsin", alpha2: "WI" },
  { name: "Wyoming", alpha2: "WY" }
];
