import { constants } from '../config';
import _ from 'lodash';
import log from './Logger'
import Utils from './Utils';
import {getAuthToken, logout} from './authtoken';
import { getTeacher, getTeacherId, getUserId } from './UserService';
const REQUESTS_TIMEOUT = 90000;

export const fetchFromServerWrapper = (url, options = {}) => {
	let { timeout = REQUESTS_TIMEOUT, ...rest } = options;
	if (rest.signal) throw new Error("Signal not supported in timeoutable fetch");
	if (getAuthToken()) {
		rest.headers = { 'Authorization': 'Bearer ' + getAuthToken(), ...rest.headers }
	}
	const controller = new AbortController();
	const { signal } = controller;
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error("Request Timed Out"));
			log.error('timeout for fetch url:' + url);
			controller.abort();
		}, timeout);
		fetch(url, { signal, ...rest })
			.finally(() => clearTimeout(timer))
			.then(resolve, reject);
	});
};

/***
 * this function performs a GET call tailored to the new json apis and returns a parsed json.
 * @param url - the URL for the API
 * @param options - (optional) addional options if needed for the fetch method.
 * @returns {Promise<JSON>}
 */
export const apiGet = async (url, options = null) => {
	options = _.defaultsDeep(options, {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			"Cache-Control": "no-cache",
		}
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => res.json());
}

export const apiGetStatus = async (url, options = null) => {
	options = _.defaultsDeep(options, {
		method: 'GET',
		headers: {'Content-Type': 'application/json'}
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => res.status === 200);
}

export const apiPost = async (url, body) => {
	const options = _.defaultsDeep(options, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		redirect: 'follow',
		body
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => res.json());
}

export const apiPostStatus = async (url, body) => {
	const options = _.defaultsDeep(options, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		redirect: 'follow',
		body
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url));
}


export const apiDelete = async (url, options = {}, isJson= true) => {
	options = _.defaultsDeep(options, {
		method: 'DELETE',
		headers: {'Content-Type': 'application/json'}
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => isJson ? res.json() : res);
}
export function createPretest(studentId) {
	const url = `${constants.classApiPath}/task/student/${studentId}/pretest`;
	return apiPostStatus(url, '')
}
export async function getAllStudentsForTeacher(teacherId) {
	const url = `${constants.classApiPath}/class/teacher/${teacherId}/students`;
	return apiGet(url)
}

export async function getAllStudentsForTeacherCount(teacherId) {
	const url = `${constants.classApiPath}/class/teacher/${teacherId}/students/count`;
	return apiGet(url)
}

export async function getNotificationStatus() {
  const url = `${constants.classApiPath}/class/notification/${getUserId()}/status`;
  return apiGet(url);
}

export async function updateNotificationStatus() {
  const url = `${constants.classApiPath}/class/notification/${getUserId()}/update`;
  return apiPostStatus(url, '').then(addSuccessToResp);
}

export async function approveNotification() {
  const url = `${constants.classApiPath}/class/notification/${getUserId()}/approve`;
  return apiPostStatus(url, '').then(addSuccessToResp);
}

export async function skipNotification() {
  const url = `${constants.classApiPath}/class/notification/${getUserId()}/skip`;
  return apiPostStatus(url, '').then(addSuccessToResp);
}

export async function googleDoLogin() {
	const url = `${constants.apiPath}/google/doLogin`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		redirect: 'follow'
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function asyncResetStudentsNew(studentIds) {
	const url = `${constants.classApiPath}/class/student/resetProgress`;
	return apiPost(url, JSON.stringify({
		studentIds
	}));
}

export async function asyncResetClassStudents(classId) {
	const url = `${constants.classApiPath}/class/${classId}/resetClassProgress`;
	return apiPost(url, '');
}

export async function asyncStopTrackingStudents(students) {
	const url = `${constants.classApiPath}/class/teacher/${getTeacherId()}/stop_tracking_students`;
	return apiPost(url, JSON.stringify(students));
}

export async function emailTeacherStudentsInfo(students, optClassId = null) {

	const transformedStudents = _.map(students, s => {
		return {
			firstName: s.first,
			lastName: s.last,
			username: s.username,
			password: s.password,
			email: s.email
		};
	});
	const payload = {};
	payload.newUsers = transformedStudents;
	if (optClassId) payload.classId = optClassId;
	const url = `${constants.apiPath}/teaching/sendTeacherStudentsInfo`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload)
	}).then(checkStatusCode(url))
		.then(res => res);
}

export async function asyncCreateStudents(students, optClassId = null) {

	const transformedStudents = _.map(students, s => {
		return {
			firstName: s.firstName,
			lastName: s.lastName,
			username: s.username,
			password: s.password,
			email: s.email
		};
	});
	const payload = {};
	payload.newUsers = transformedStudents;
	if (optClassId) payload.classId = optClassId;
	payload.emailTeacher = false;
	const url = `${constants.apiPath}/teaching/createStudents`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload)
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function createClass(newClass) {
	const url = `${constants.classApiPath}/class`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow',
		body: JSON.stringify({
			name: newClass.name,
			below_thirteen: newClass.below_thirteen,
			grade: newClass.grade,
			//TODO: @Shuky please validate
			include_short_essay_questions: false,
			may_skip_short_essay: false,
			teacherId: getTeacher().teacherId,
			class_setting: {
				show_grade: newClass.gradeOn,
				show_points: newClass.showPoints,
				ads_disabled: newClass.noAdsOn,
				min_level: newClass.minLevel,
				max_level: newClass.maxLevel,
				icon_url: newClass.iconUrl,
				icon_bg_color: newClass.iconBgColor
			}
		})
	}).then(checkStatusCode(url))
		.then(res => res.json());


	//TODO: Why this is needed
	//FIXME: @Shuky -> I think that if one of them exists grails assumes this feature is turned on
	// if (newClass.minLevel === 1 && newClass.maxLevel === 12) {
	// 	delete classSetting.min_level
	// 	delete classSetting.max_level
	// }
}

export async function updateClass(editClass) {
	const url = `${constants.classApiPath}/class/${editClass.classId}`;
	return fetchFromServerWrapper(url, {
		method: 'PUT',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow',
		body: JSON.stringify({
			name: editClass.name,
			below_thirteen: editClass.below_thirteen,
			grade: editClass.grade,
			include_short_essay_questions: editClass.include_short_essay_questions,
			may_skip_short_essay: editClass.may_skip_short_essay,
			teacherId: getTeacher().teacherId,
			class_setting: {
				show_grade: editClass.gradeOn,
				show_points: editClass.showPoints,
				ads_disabled: editClass.noAdsOn,
				min_level: editClass.minLevel,
				max_level: editClass.maxLevel,
				icon_url: editClass.iconUrl,
				icon_bg_color: editClass.iconBgColor,
			}
		})
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function deleteClass(classId) {
	const data = new URLSearchParams();
	data.append('currentClassId', classId);
	const url = `${constants.apiPath}/teaching/deleteClass`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function deleteClassNew(classId) {
	const url = `${constants.classApiPath}/class/${classId}`;
	return apiDelete(url, {}, false)
}

export async function removeTeacherFromClass(classId, inviteId) {
	const url = `${constants.classApiPath}/class/${classId}/invite/${inviteId}`;
	return apiDelete(url, {}, false)
}

export async function getAllClassesForTeacher() {
	const url = `${constants.apiPath}/teaching/classes`;
	const json = await fetchFromServerWrapper(url)
		.then(checkStatusCode(url))
		.then(res => res.json());
	return json;
}

export async function getTopClasses() {
	const url = `${constants.apiPath}/teaching/top_active_classes`
	const json = await fetchFromServerWrapper(url)
		.then(checkStatusCode(url))
		.then(res => res.json());
	return json;
}

export async function fetchClassStats(classId, beginDate, endDate, jsonFormat = false) {
	const data = new URLSearchParams();
	data.append('classId', classId);
	data.append('beginDateString', beginDate);
	data.append('endDateString', endDate);
	data.append('jsonFormat', jsonFormat);

	const url = `${constants.apiPath}/teaching/viewClassStats`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => jsonFormat ? res.json() : res.text());
}

export async function toggleClassAds(classId, showAds) {
	const data = new URLSearchParams();
	data.append('classId', classId);
	data.append('showAds', showAds);

	const url = `${constants.apiPath}/teaching/toggleClassAds`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getClass(classId) {
	const url = `${constants.classApiPath}/class/${classId}`;
	return apiGet(url)
}

export async function getClasses() {
	const url = `${constants.apiPath}/teaching/classList`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getStudentClassList(studentId) {
	const url = `${constants.classApiPath}/class/student/${studentId}/classes`;
	return apiGet(url)
}

export async function getStudentPretestStatusForClass(classId) {
	const url = `${constants.classApiPath}/class/${classId}/pretestStatuses`;
	return apiGet(url)
}

export async function getClassStudents(classId) {
	const url = `${constants.classApiPath}/class/${classId}/students`;
	return apiGet(url)
}

export async function getClassList() {
	const url = `${constants.classApiPath}/class/teacher/${getTeacher().teacherId}`;
	return apiGet(url)
}

export async function getClassTeachers(classId) {
	const url = `${constants.classApiPath}/class/${classId}/teachers`;
	return apiGet(url)
}

export async function getClassInvites() {
	const url = `${constants.classApiPath}/class/teacher/${getTeacherId()}/invites`;
	return apiGet(url);
}

export async function rejectClassInvite(classId, inviteId) {
	const url = `${constants.classApiPath}/class/${classId}/invite/${inviteId}/reject`;
	return apiGetStatus(url);
}

export async function acceptClassInvite(classId, inviteId) {
	const url = `${constants.classApiPath}/class/${classId}/invite/${inviteId}/accept`;
	return apiGetStatus(url);
}

export async function createClassInvites(classId, emails) {
	const url = `${constants.classApiPath}/class/${classId}/teacher/invite`;
	return apiPost(url, JSON.stringify({ emails }));
}

export async function deleteClassInvite(classId, inviteId) {
	const url = `${constants.classApiPath}/class/${classId}/invite/${inviteId}`;
	return apiDelete(url, {}, false);
}

export async function getAssignment() {
	const url = `${constants.apiPath}/teaching/findAssignment`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getTeacherAssignmentsCount() {
	const url = `${constants.activityApiPath}/activity/teacher/${getTeacherId()}/count`;
	return apiGet(url);
}

export async function teacherAssignmentReport(fromDate = null, toDate = null) {
	const payload = {};
	if (fromDate) {
		payload.fromDate = fromDate;
	}
	if (toDate) {
		payload.toDate = toDate;
	}

	const url = `${constants.apiPath}/teaching/teacherAssignmentReport`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getStudentSummaryPdf(studentIds, startDate, endDate) {

	const payload = {
		studentIds,
		startDate,
		endDate
	}

	const url = `${constants.classApiPath}/pdf/student_summary_report`

	return apiPost(url, JSON.stringify(payload));

}

export async function createReport(studentIds, startDate, endDate, type) {
	const payload = {
		studentIds,
		startDate,
		endDate,
		reportType: type
	};
	const url = `${constants.classApiPath}/class/student/generateReportData`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function createReportStatus(taskId) {
	const url = `${constants.classApiPath}/job/getJobStatus?messageId=${taskId}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function fetchCatalogQuizzes() {
	const url = `${constants.apiPath}/teaching/listCatalogQuizzes`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function createAssignment(payload) {
	const url = `${constants.apiPath}/teaching/createAssignment`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function assignmentTypes() {
	const url = `${constants.apiPath}/teaching/assignmentTypes`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function updateAssignment(assignmentId, studentIds) {
	const payload = {
		studentIds,
		assignmentId
	};
	const url = `${constants.apiPath}/teaching/updateAssignment`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function pauseAssignment(assignmentId, pause) {
	const url = `${constants.apiPath}/teaching/pauseAssignment?assignmentId=${assignmentId}&pause=${pause}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function cancelAssignment(assignmentId) {
	const url = `${constants.apiPath}/teaching/cancelAssignment?assignmentId=${assignmentId}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function uncancelAssignment(assignmentId) {
	const url = `${constants.apiPath}/teaching/uncancelAssignment?assignmentId=${assignmentId}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function findStudentAssignments(studentId, fromDate, toDate) {
	const payload = {
		studentId
	};
	if (fromDate) {
		payload.fromDate = fromDate;
	}
	if (toDate) {
		payload.toDate = toDate;
	}

	const url = `${constants.apiPath}/reading/findStudentAssignments`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: JSON.stringify(payload)
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function startAssignment(assignmentId) {
	const data = new URLSearchParams();
	data.append('assignmentId', assignmentId);
	const url = `${constants.apiPath}/reading/startAssignment`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getStudentAssignmentReport(assignmentId) {
	const url = `${constants.apiPath}/reading/studentAssignmentReport?assignmentId=${assignmentId}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getTeachers(assignmentId) {
	const url = `${constants.apiPath}/reading/myTeachers`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function fetchStudentProfile(studentId, beginDate, endDate, jsonFormat = false) {
	const data = new URLSearchParams();
	data.append('studentId', studentId);
	data.append('beginDateString', beginDate);
	data.append('endDateString', endDate);
	data.append('jsonFormat', jsonFormat);

	const url = `${constants.apiPath}/dashboard/viewProfileForStudent`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => jsonFormat ? res.json() : res.text());
}

export async function viewQuizHistory(studentId, pageNumber, beginDate, endDate) {
	const data = new URLSearchParams();
	data.append('studentId', studentId);
	data.append('pageNumber', pageNumber);
	data.append('beginDateString', beginDate);
	data.append('endDateString', endDate);
	data.append('jsonFormat', true);

	const url = `${constants.apiPath}/dashboard/viewQuizHistory`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function fetchClassStudents(classId) {
	const data = new URLSearchParams();
	data.append('classId', classId);
	data.append('fetchJSON', true);

	const url = `${constants.apiPath}/teaching/renderSelectStudent`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function asyncResetStudents(students) {

	const data = new URLSearchParams();
	data.append('students', JSON.stringify(students));

	const url = `${constants.apiPath}/teaching/resetStudents`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function fetchProgressLevels() {
	const url = `${constants.apiPath}/reading/progressLevels`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function checkUsernameAvailability(username) {
	const data = new URLSearchParams();
	data.append('username', username);
	const url = `${constants.apiPath}/welcome/validateUsername`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function checkUsernamesAndEmailsAvailability(actionableList) {
	const payload = {
		actionableList
	};
	const url = `${constants.apiPath}/welcome/validateUsernamesAndEmails`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function asyncSuggestUsername(first, last) {
	const data = new URLSearchParams();
	data.append('firstName', first);
	data.append('lastName', last);
	const url = `${constants.apiPath}/welcome/suggestUsername`;
	try {
		const res = await fetchFromServerWrapper(url, {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'x-requested-with': 'XMLHttpRequest',
			},
			redirect: 'follow', // manual, *follow, error
			body: data,
		}).then(checkStatusCode(url))
			.then(res => res.json())
			.then(({ success, username }) => {
				if (success) {
					return username;
				} else {
					log.error('api::suggestUserName call returned no username');
					return first + '_' + last;
				}
			})
		return res;
	}
	catch (e) {
		log.error('api::suggestUserName call failed', e)
		return first + '_' + last;
	}
}


export async function checkEmailAvailability(email) {
	const data = new URLSearchParams();
	data.append('email', email);
	const url = `${constants.apiPath}/welcome/validateEmail`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function registerAccount(username, password, dob, role, optionalClassCode = null, ref = null) {

	const ENDPOINTS = {
		'default': '/welcome/registerTeacher',
		'teacher': '/welcome/registerTeacher',
		'student': '/welcome/registerStudent',
		'parent': '/welcome/registerStudent',
	};

	let endpoint = ENDPOINTS[role];
	if (_.isEmpty(endpoint)) endpoint = ENDPOINTS['default'];
	log.debug('register account endpoint: ', endpoint);

	const data = new URLSearchParams();
	data.append('username', username);
	data.append('password', password);
	if (!_.isEmpty(dob)) {
		data.append('dob', dob);
	}
	if (!_.isEmpty(optionalClassCode)) {
		data.append('classCode', optionalClassCode)
	}
	const tz = Utils.getTimeZoneFromBrowser()
	if (!_.isEmpty(tz)) {
		data.append('timezone', tz)
	}
	data.append('registerAs', role);
	data.append('registerTeacher', 'Sign Up');
	if (ref) {
		data.append('ref', ref);
	}

	const url = `${constants.apiPath}${endpoint}`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())

}

export async function updateUserSettings(first, last, email, dob) {
	const data = new URLSearchParams();
	data.append('firstname', first);
	data.append('lastname', last);
	data.append('email', email || '');
	data.append('dob', dob);
	data.append('control', 'user');
	data.append('act', 'updateUserSettings');

	const url = `${constants.apiPath}/user/updateUserSettings`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updateUserProfile(username, first, last, email, dob, userSettings, timezone) {
	const data = new URLSearchParams();
	data.append('username', username);
	data.append('firstname', first);
	data.append('lastname', last);
	data.append('email', email);
	data.append('dob', dob);
	data.append('userSettings', userSettings);
	data.append('timezone', timezone);
	data.append('act', 'updateUserProfile');

	const url = `${constants.apiPath}/user/updateUserProfile`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updateStudentRealname(firstName, lastName) {
	const data = new URLSearchParams();
	data.append('firstname', firstName);
	data.append('lastname', lastName);

	const url = `${constants.apiPath}/user/updateStudentRealname`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function getUserSocialProviders() {
	const url = `/v2/auth/password/${getUserId()}/socialProviders`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function updateStudentAge(age) {
	const data = new URLSearchParams();
	data.append('age', age);

	const url = `${constants.apiPath}/user/updateUserAge`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updateUserSettingsOnly(userSettings) {
	const data = new URLSearchParams();
	data.append('userSettings', userSettings);

	const url = `${constants.apiPath}/user/updateUserSettingsOnly`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function inviteTeacher(email) {
	const data = new URLSearchParams();
	data.append('email', email);

	const url = `${constants.apiPath}/reading/inviteTeacher`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updatePassword(userId, oldPassword, newPassword, confirmNewPassword) {
	const payload = {
		userId,
		oldPassword,
		newPassword,
		confirmNewPassword
	};
	const url = `${constants.apiPath}/user/updatePassword`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function joinClass(classCode) {
	const data = new URLSearchParams();
	data.append('classCode', classCode);

	const url = `${constants.apiPath}/reading/joinClass`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updateTeacherDetails(first, last, email, userSettings) {
	const data = new URLSearchParams();
	data.append('firstname', first);
	data.append('lastname', last);
	data.append('email', email);
	data.append('userSettings', userSettings);
	data.append('control', 'user');
	data.append('act', 'updateTeacherDetails');
	data.append('changeSettings', 'Update');

	const url = `${constants.apiPath}/user/updateTeacherDetails`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function deleteStudentInvite(inviteId) {
	const data = new URLSearchParams();
	data.append('inviteId', inviteId);

	const url = `${constants.apiPath}/teaching/deleteStudentInvite`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function addStudentsToClass(targetClassId, targetClassName, actionableStudents) {
	const payload = {
		targetClassId,
		targetClassName,
		actionableStudents
	};
	const url = `${constants.apiPath}/teaching/addStudentsToClass`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function removeStudentsFromClass(targetClassId, targetClassName, actionableStudents) {
	const payload = {
		targetClassId,
		targetClassName,
		actionableStudents
	};
	const url = `${constants.apiPath}/teaching/removeStudentsFromClass`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function resetPasswords(newPassword, confirmNewPassword, actionableStudents, mustChangePassword) {
	const payload = {
		newPassword,
		confirmNewPassword,
		actionableStudents,
		mustChangePassword
	};
	const url = `${constants.apiPath}/teaching/resetPasswords`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			"Content-Type": "application/json",
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(payload),
	}).then(checkStatusCode(url))
		.then(res => res.json())
}

export async function updateCoppaClasses(classes) {
	const url = `${constants.apiPath}/teaching/update_coppa_classes`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(classes),
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function startQuiz() {
	const url = `${constants.apiPath}/reading/startQuiz`;

	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function answerQuestion(answerId, parentId) {
	const data = new URLSearchParams();
	data.append('isSelected', true);
	data.append('answerId', answerId);
	data.append('parentId', parentId);

	const url = `${constants.apiPath}/reading/answerQuestion`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function answerShortEssayQuestion(readingComprehensionQuizId, shortEssayQuestionId, responseText) {
	const data = new URLSearchParams();
	data.append('readingComprehensionQuizId', readingComprehensionQuizId);
	data.append('shortEssayQuestionId', shortEssayQuestionId);
	data.append('responseText', responseText);

	const url = `${constants.apiPath}/reading/answerShortEssayQuestion`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data,
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function skipShortEssayQuestion(shortEssayQuestionId) {

	const data = new URLSearchParams();
	data.append('shortEssayQuestionId', shortEssayQuestionId)

	const url = `${constants.apiPath}/reading/skipShortEssayQuestion`;

	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
		body: data
	}).then(checkStatusCode(url))
		.then(res => res.json());

}

export async function quizResposne(studentQuizResponseId) {
	const url = `${constants.apiPath}/reading/reviewQuizResponseData?studentQuizResponseId=${studentQuizResponseId}`;

	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getPretestQuiz() {
	const url = `${constants.apiPath}/reading/presentPretest`;

	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getStripeCustomerDetail(stripeKey, customerId) {
	const url = `https://api.stripe.com/v1/customers/${customerId}`;
	return fetchFromServerWrapper(url, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			'Authorization': `Bearer ${stripeKey}`,
		},
		redirect: 'follow',
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function createSubscriptionCheckoutSession(
	email, priceId, successUrl, cancelUrl, couponId, user_id, trial_period, no_cc, campaign) {
	const data = {
		priceId,
		success_url: successUrl,
		cancel_url: cancelUrl,
	}
	if (email) {
		data.email = email;
	}
	if (couponId) {
		data.coupon_id = couponId;
	}
	if (user_id) {
		data.user_id = user_id;
	}
	if (trial_period) {
		data.trial_period = trial_period;
	}
	if (no_cc) {
		data.no_cc = true
	}
	if (campaign) {
		data.campaign = campaign
	}
	const url = `${constants.subscriptionApiPath}/checkout/create`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/json',
		},
		redirect: 'follow', // manual, *follow, error
		body: JSON.stringify(data)
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function createQuote(name, email,
	school_name,
	address_line1, address_line2,
	address_zip, address_city,
	address_state,
	address_country,
	priceId, couponId) {
	const data = new URLSearchParams();
	if (name) data.append('name', name);
	if (email) data.append('email', email);
	if (school_name) data.append('school_name', school_name);
	if (address_line1) data.append('address_line1', address_line1);
	if (address_line2) data.append('address_line2', address_line2);
	if (address_zip) data.append('address_zip', address_zip);
	if (address_city) data.append('address_city', address_city);
	if (address_state) data.append('address_state', address_state);
	if (address_country) data.append('address_country', address_country);
	data.append('priceId', priceId);
	if (couponId) data.append('couponId', couponId);
	const url = `${constants.apiPath}/subscription/createAndDownloadQuoteForCustomer`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data
	}).then(checkStatusCode(url))
		.then(async res => {
			const filename = "readtheory" + res.headers.get('content-disposition').split('filename=')[1].split('.pdf')[0] + ".pdf";
			const blob = await res.blob();
			// It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
			const newBlob = new Blob([blob], { type: 'application/pdf' });

			// MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
			} else {
				// For other browsers: create a link pointing to the ObjectURL containing the blob.
				const objUrl = window.URL.createObjectURL(newBlob);

				let link = document.createElement('a');
				link.href = objUrl;
				link.download = filename;
				link.target = '_blank'
				link.click();

				// For Firefox it is necessary to delay revoking the ObjectURL.
				setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
			}
		})
}



export async function getBillingPortalUrl(customerId, returnUrl) {
	const data = new URLSearchParams();
	data.append('customerId', customerId);
	data.append('returnUrl', returnUrl);
	const url = `${constants.apiPath}/subscription/getBillingPortalUrl`;
	return fetchFromServerWrapper(url, {
		method: 'POST',
		cache: 'no-cache',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'x-requested-with': 'XMLHttpRequest',
		},
		redirect: 'follow', // manual, *follow, error
		body: data
	}).then(checkStatusCode(url))
		.then(res => res.json());
}

export async function getMapAddress(lat, lon) {
	const url = `${constants.mapApiPath}/reverse?format=json&lat=${lat}&lon=${lon}`;
    const apiResponse = await fetch(url);
    return await apiResponse.json();
}

export async function getTeacherDistrictLimitations() {
	const url = `${constants.settingsApiPath}/settings/district-limitations/teacher/${getTeacherId()}`;
	return apiGet(url);
}

export async function getMapLocation(country, city, address) {
	let queryParams = '';
	if (country) {
		queryParams = `country=${country}`;
	}
	if (city) {
		queryParams += (queryParams ? '&' : '') + `city=${city}`;
	}
	if (address) {
		queryParams += (queryParams ? '&' : '') + `q=${address}`;
	}
	const url = `${constants.mapApiPath}/search?format=json&` + queryParams;
	const apiResponse = await fetch(url);
	return await apiResponse.json();
}

export async function isTeacherConnectedToSchool(teacherId){
	const url = `${constants.schoolApiPath}/school/teacher/${teacherId}`;
	try {
		const res = await apiGet(url);
		return res.schoolId !== 0;
	} catch (e) {
		return true;
	}
}

export async function getSchools(schoolName, countryCode) {
	const url = `${constants.schoolApiPath}/school/_search`;
	return apiPost(url, JSON.stringify({ query: schoolName, country_code: countryCode }));
}

export async function getSchoolInfoForUser(userId) {
	const url = `${constants.schoolApiPath}/school/info/user/${userId}`;
	return apiGet(url)
}

export async function updateUserSchool(schoolId) {
	const url = `${constants.schoolApiPath}/school/${schoolId}/teacher/${getTeacherId()}`;
	return apiPostStatus(url, '').then(res=>addSuccessToResp(res));
}

export async function createCustomSchool(customSchoolData) {
  const url = `${constants.schoolApiPath}/school/custom`;
  return apiPostStatus(
    url,
    JSON.stringify({
      teacherId: getTeacherId(),
      ...customSchoolData,
    })
  ).then((res) => addSuccessToResp(res));
}

export async function getUserSchoolAccount(userId) {
  const url = `${constants.schoolApiPath}/school/account/user/${userId}`;
  return apiGet(url);
}
export async function disconnect() {
	const url = `/auth/disconnect`;
	return apiGet(url);
}

export async function submitQuote(payload) {
	const url = `${constants.newSubscriptionApiPath}/subs/quote`;
	return apiPostStatus(url,  JSON.stringify({ user_id: getUserId(), ...payload })).then(res=>addSuccessToResp(res));
}

const addSuccessToResp = (res)=> {
	return {
		success: res.status >= 200 && res.status < 300
	};
}
export const checkStatusCode = _.memoize((url = 'unknown') => {
	return (response) => {
		if (response.status === 202 || response.status === 200 || response.status === 201 || response.status === 304) {
			return response;
		}
		const error = new Error(`response returned with bad status code`);
		error.response = response;
		error.status = response.status;
		log.error('API error', { url: url, status: response.status });
		if (response.status === 401) {
			logout()
		}
		throw error;
	}
});
